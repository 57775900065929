@charset "UTF-8";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  border-radius: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  font-size: 100%;
  vertical-align: baseline;
}

img {
  max-width: 100%;
}

body {
  font-size: 1em;
  line-height: 1.25;
}

body, caption, th, td, input, textarea, select, option, legend, fieldset, h1, h2, h3, h4, h5, h6 {
  font-size-adjust: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

p, article ul {
  line-height: 1.3;
}

a {
  color: #1daced;
}

#page {
  font-size: 1em;
  /* equivalent to 16px */
  line-height: 1.25;
  /* equivalent to 20px */
}

@media (min-width: 43.75em) {
  #page {
    font-size: 1em;
    /* equivalent to 16px */
    line-height: 1.375;
    /* equivalent to 22px */
  }
}
h1 {
  font-size: 2em;
  /* 2x body copy size = 32px */
  line-height: 1.25;
  /* 45px / 36px */
  margin: 0 0 0.6em;
}

@media (min-width: 43.75em) {
  h1 {
    font-size: 2.5em;
    /* 2.5x body copy size = 40px */
    line-height: 1.125;
  }
}
@media (min-width: 56.25em) {
  h1 {
    font-size: 3em;
    /* 3x body copy size = 48px */
    line-height: 1.05;
    /* keep to a multiple of the 20px line height and something more appropriate for display headings */
  }
}
h2 {
  font-size: 1.625em;
  /* 1.625x body copy size = 26px */
  line-height: 1.15384615;
  /* 30px / 26px */
  margin: 0 0 0.8em;
}

@media (min-width: 43.75em) {
  h2 {
    font-size: 2em;
    /* 2x body copy size = 32px */
    line-height: 1.25;
  }
}
@media (min-width: 56.25em) {
  h2 {
    font-size: 2.25em;
    /* 2.25x body copy size = 36px */
    line-height: 1.25;
  }
}
h3 {
  font-size: 1.375em;
  /* 1.375x body copy size = 22px */
  line-height: 1.13636364;
  /* 25px / 22px */
  margin: 0 0 1em;
}

@media (min-width: 43.75em) {
  h3 {
    font-size: 1.5em;
    /* 1.5x body copy size = 24px */
    line-height: 1.25;
  }
}
@media (min-width: 56.25em) {
  h3 {
    font-size: 1.75em;
    /* 1.75x body copy size = 28px */
    line-height: 1.25;
  }
}
h4 {
  font-size: 1.125em;
  /* 1.125x body copy size = 18px */
  line-height: 1.11111111;
}

@media (min-width: 43.75em) {
  h4 {
    line-height: 1.22222222;
    /* (22px / 18px */
  }
}
blockquote {
  font-size: 1.25em;
  /* 20px / 16px */
  line-height: 1.25;
  /* 25px / 20px */
}

@media (min-width: 43.75em) {
  blockquote {
    font-size: 1.5em;
    /* 24px / 16px = */
    line-height: 1.45833333;
    /* 35px / 24px */
  }
}
.content-list-elements {
  padding: 10px 0;
  border-bottom: 1px solid #a0a0a0;
}

.pager {
  font-size: 0;
  text-align: center;
}
.pager > * {
  font-size: initial;
}

article {
  line-height: 1.2;
}
article table td {
  padding: 2px 3px;
}

.article-lead,
.article-body {
  margin: 1em 0;
}
.article-lead p,
.article-body p {
  margin-bottom: 1em;
  margin-top: 1em;
}
.article-lead ul, .article-lead ol,
.article-body ul,
.article-body ol {
  line-height: 1.3;
  margin-left: 12px;
  padding-left: 24px;
}

.article-body ul {
  list-style-type: disc;
}
.article-body ol {
  list-style-type: decimal;
}

.label {
  padding-left: 0.25em;
  padding-right: 0.25em;
}

header .DynamicMenuPortlet,
.BreadcrumbPortlet {
  margin: 2em 0;
}

.ContentViewPortlet {
  margin-bottom: 2em;
}

/*AttachmentPortlet */
.attachments ul {
  margin: 0 0 0 1em;
}
.attachments ul li {
  margin: 1em 0;
}
.attachments ul li .document-icon {
  width: 38px;
  height: 38px;
  display: inline-block;
  margin: 0 10px 0 0;
  vertical-align: middle;
}

/*ContentListPortlet */
.content-list-elements .content-document .document-icon {
  width: 38px;
  height: 38px;
  display: inline-block;
  margin: 0 10px 0 0;
  vertical-align: middle;
}

/*ContentViewPortlet*/
.article-tags {
  margin: 1em 0;
  display: flex;
}
.article-tags ul {
  margin: 0 0.5em;
}
.article-tags ul li.tag {
  display: inline-block;
  padding: 2px 4px;
  background: #1daced;
  color: #fff;
  border-radius: 2px;
}

/*DynamicBreadcrumbPortlet*/
.breadcrumb {
  margin: 2em 0;
  font-size: 0.8em;
}
.breadcrumb li {
  display: inline-block;
  margin: 0;
}
.breadcrumb li ~ li:before {
  display: inline-block;
  content: ">";
  margin-right: 5px;
}

#skiplink {
  margin: 0;
  position: relative;
}
#skiplink ul {
  list-style: none;
  line-height: 1;
  margin-bottom: 0;
}
#skiplink ul li {
  margin-bottom: 0;
}
#skiplink ul li a {
  position: absolute;
  left: 40%;
  left: calc(50% - 7em);
  top: -5em;
  width: 14em;
  background-color: red;
  color: #fff;
  text-decoration: none;
  padding: 1em 0;
  margin: 0 auto;
  text-align: center;
  transition: 0.2s top ease-out;
  display: block;
  z-index: 100;
}
#skiplink ul li a:focus, #skiplink ul li a:hover {
  top: 0;
  outline-color: red;
}

.row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.row > .column {
  margin: 0 0 1.8%;
  box-sizing: border-box;
}

.row > .column ~ .column {
  margin-left: 2%;
}

.grid_2_1 {
  flex: 1 1 49%;
}

.grid_3_1 {
  flex: 1 1 32%;
}

.grid_3_2 {
  flex: 1 1 66%;
}

.grid_4_1 {
  flex: 1 1 23.2%;
}

.grid_4_3 {
  flex: 1 1 74.2%;
}

.container {
  margin: 0 2em;
}

.container .container::before, .container-fluid .container::before, .container .container-fluid::before, .container-fluid .container-fluid::before {
  content: "Konténerben konténer nem engedélyezett" !important;
  color: #fff !important;
  background-color: red !important;
  font-size: 38px !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  text-align: center !important;
  z-index: 9999 !important;
}

.vertical-flex-page {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-flow: column;
  width: 100%;
}

.vertical-flex-page > .container {
  display: flex;
  flex-flow: column;
  flex: 1 0 auto;
}

.vertical-flex-page > .container > * {
  flex: 0 0 auto;
}

.vertical-flex-page > .container > main {
  flex: 1 0 auto;
  display: flex;
  flex-flow: column;
}

.page-editor-columns {
  display: flex;
  justify-content: space-between;
}
.page-editor-columns main {
  width: 68%;
  margin-bottom: 4em;
}
.page-editor-columns aside {
  width: 30%;
  margin-bottom: 4em;
}
.page-editor-columns .left-column,
.page-editor-columns .right-column {
  width: 24%;
}
.page-editor-columns main.center-column {
  width: 48%;
}

a.logo {
  display: block;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

input[type=color],
input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
input[type=week],
input:not([type]),
textarea {
  font-size: inherit;
  line-height: inherit;
  border: 1px solid currentColor;
  background: #fff;
  -webkit-appearance: none;
          appearance: none;
  color: inherit;
  box-sizing: border-box;
  width: 100%;
  font-family: inherit;
}

select {
  width: 100%;
  font-size: inherit;
  line-height: inherit;
  border: 1px solid currentColor;
}

textarea {
  resize: vertical;
  height: 100px;
}

input[type=button],
input[type=submit],
button {
  font-size: inherit;
  line-height: inherit;
  border: 1px solid #000;
  background: #2b52ff;
  -webkit-appearance: none;
          appearance: none;
  box-sizing: border-box;
}

div.embed-container {
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
div.embed-container > * {
  width: 100%;
}
/*# sourceMappingURL=portal.css.map */